


















import { DrawerTab } from '@/store/modules/app/types';
import {
  Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import AsyncLoading from '@/components/base/AsyncLoading.vue';
import AsyncError from '@/components/base/AsyncError.vue';

const ReportsCard: any = () => ({
  component: import('./ReportsCard/ReportsCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const CreatorCard: any = () => ({
  component: import('./CreatorCard/CreatorCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const PlanningCard: any = () => ({
  component: import('./PlanningCard/PlanningCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const OptimizationCard: any = () => ({
  component: import('./OptimizationCard/OptimizationCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const AnalyticsCard: any = () => ({
  component: import('./AnalyticsCard/AnalyticsCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const DevicesCard: any = () => ({
  component: import('./DevicesCard/DevicesCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const UsersCard: any = () => ({
  component: import('./UsersCard/UsersCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const ArchiveCard: any = () => ({
  component: import('./ArchiveCard/ArchiveCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

const SubmissionsCard: any = () => ({
  component: import('./SubmissionsCard/SubmissionsCard.vue'),
  loading: AsyncLoading,
  error: AsyncError,
  delay: 0,
  timeout: 3000
});

@Component({
  components: {
    ReportsCard,
    CreatorCard,
    PlanningCard,
    OptimizationCard,
    AnalyticsCard,
    DevicesCard,
    UsersCard,
    ArchiveCard,
    SubmissionsCard,
  }
})
export default class TheMainCard extends Vue {
  @Prop(Number) readonly selectedTab!: DrawerTab

  get isVertical() {
    return [DrawerTab.Analytics, DrawerTab.Reports].includes(this.selectedTab);
  }

  @Watch('selectedTab')
  onSelectedTabChange() {
  }

  BindStatus(vertical: Boolean, selected: Number) {
    if (vertical === true) {
      return 'c-menu-card--vertical c-menu-card rounded-xl';
    }
    if (selected === 0) {
      return 'pc-display c-menu-card rounded-xl';
    }
    if (vertical === true && selected === 0) {
      return 'pc-display c-menu-card--vertical c-menu-card rounded-xl';
    }
    return 'c-menu-card rounded-xl';
  }
}
